import React, { useState, useEffect, useRef } from "react";
import "./SpeedTyping.css";
import backImgDark from './darkBackg.svg';
import backImgWhi from './lightBackg.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";


const SpeedTyping = () => {
  const typData = [
    {
      id: 1,
      difficulty: "easy",
      text: "Typing is a valuable skill that helps improve communication and efficiency in both personal and professional settings. Regular practice is essential to developing faster and more accurate typing abilities. Start with shorter practice sessions and gradually increase the duration as you become more comfortable. By focusing on accuracy and speed, you can enhance your typing skills and become more proficient over time. Effective typing practices can greatly enhance your productivity and efficiency. Start by learning proper finger placement and using all ten fingers while typing. Practice typing regularly to build your skills and confidence. Focus on accuracy first, then gradually work on increasing your typing speed. With consistent effort, you will find that your typing skills improve significantly, making your tasks easier and more enjoyable. Additionally, developing strong typing skills is an important part of modern communication. Begin by familiarizing yourself with the keyboard layout and practicing regularly. Use typing exercises to build muscle memory and improve your speed. Remember to keep your hands relaxed and maintain a comfortable typing posture. As you gain experience, you will notice improvements in both speed and accuracy, leading to more efficient work and less fatigue.",
      wordCount: 320,
      characterCount: 1700,
    },
    {
      id: 2,
      difficulty: "medium",
      text: "The art of typing is more than just hitting keys on a keyboard. It involves developing muscle memory, learning proper hand placement, and maintaining a steady rhythm. As you type, try to minimize errors and avoid looking at the keyboard. Instead, focus on the screen and aim to type smoothly and consistently. With regular practice and dedication, you'll find yourself typing faster and more accurately in no time. Mastering typing requires more than just practice; it involves understanding how to position your hands correctly and developing a rhythm that allows for smooth and efficient typing. Utilize typing software or online tools to track your progress and identify areas for improvement. Set achievable goals for speed and accuracy, and make typing a regular part of your routine. Over time, these practices will help you become a more proficient typist. Additionally, the process of learning to type efficiently involves understanding the mechanics of typing and developing good habits. Start by practicing with texts that challenge your skills and help you improve. Focus on maintaining proper technique, including hand positioning and posture. Track your progress with typing tests and set incremental goals to keep yourself motivated. With time and consistent effort, you'll achieve better typing speed and accuracy.",
      wordCount: 335,
      characterCount: 1800,
    },
    {
      id: 3,
      difficulty: "hard",
      text: "In today's digital world, typing quickly and accurately is a crucial skill for almost any profession. From writing emails and reports to coding and data entry, efficient typing can save you time and reduce the risk of errors. To improve your typing speed, practice with a variety of texts, including technical material, literature, and everyday content. Pay attention to your typing technique and work on building both speed and accuracy. The ability to type efficiently can greatly impact your productivity and effectiveness in various tasks. Whether you're drafting documents, entering data, or communicating with colleagues, fast and accurate typing skills are essential. Focus on maintaining proper posture and hand positioning to prevent strain and injury. Engage in regular practice sessions and challenge yourself with diverse texts to continually improve your typing speed and accuracy. Additionally, the efficiency of your typing can greatly impact your overall productivity and effectiveness in various tasks. Engaging in regular practice with varied content, such as technical material and literature, can help enhance both speed and accuracy. Pay attention to ergonomic practices to prevent strain and ensure a comfortable typing experience.",
      wordCount: 355,
      characterCount: 1900,
    },
    {
      id: 4,
      difficulty: "easy",
      text: "Effective typing practices can greatly enhance your productivity and efficiency. Start by learning proper finger placement and using all ten fingers while typing. Practice typing regularly to build your skills and confidence. Focus on accuracy first, then gradually work on increasing your typing speed. With consistent effort, you will find that your typing skills improve significantly, making your tasks easier and more enjoyable. Developing strong typing skills is an important part of modern communication. Begin by familiarizing yourself with the keyboard layout and practicing regularly. Use typing exercises to build muscle memory and improve your speed. Remember to keep your hands relaxed and maintain a comfortable typing posture. As you gain experience, you will notice improvements in both speed and accuracy, leading to more efficient work and less fatigue. The ability to type quickly and accurately is a valuable asset in today’s fast-paced world. Begin by practicing with shorter texts and gradually move on to longer and more complex content. Aim to improve your speed and accuracy through consistent practice and by focusing on proper technique.",
      wordCount: 320,
      characterCount: 1700,
    },
    {
      id: 5,
      difficulty: "medium",
      text: "Mastering typing requires more than just practice; it involves understanding how to position your hands correctly and developing a rhythm that allows for smooth and efficient typing. Utilize typing software or online tools to track your progress and identify areas for improvement. Set achievable goals for speed and accuracy, and make typing a regular part of your routine. Over time, these practices will help you become a more proficient typist. Typing efficiently is a skill that can be developed through consistent practice and attention to technique. Start by focusing on accuracy before attempting to increase your typing speed. Use a variety of practice texts to challenge yourself and avoid getting stuck in a routine. Regularly assess your progress and set new goals to push your limits. With dedication and effort, you will achieve noticeable improvements in your typing skills. The process of learning to type efficiently involves understanding the mechanics of typing and developing good habits. Start by practicing with texts that challenge your skills and help you improve. Focus on maintaining proper technique, including hand positioning and posture. Track your progress with typing tests and set incremental goals to keep yourself motivated. With time and consistent effort, you'll achieve better typing speed and accuracy.",
      wordCount: 360,
      characterCount: 1850,
    },
    {
      id: 6,
      difficulty: "hard",
      text: "The ability to type efficiently can greatly impact your productivity and effectiveness in various tasks. Whether you're drafting documents, entering data, or communicating with colleagues, fast and accurate typing skills are essential. Focus on maintaining proper posture and hand positioning to prevent strain and injury. Engage in regular practice sessions and challenge yourself with diverse texts to continually improve your typing speed and accuracy. In today's digital world, typing quickly and accurately is a crucial skill for almost any profession. From writing emails and reports to coding and data entry, efficient typing can save you time and reduce the risk of errors. To improve your typing speed, practice with a variety of texts, including technical material, literature, and everyday content. Pay attention to your typing technique and work on building both speed and accuracy. Typing is an essential skill that can greatly impact your efficiency and productivity in various tasks. To enhance your typing abilities, engage in regular practice with diverse text samples. Concentrate on both speed and accuracy, and use typing software to monitor your progress. Incorporate ergonomic practices to prevent discomfort and strain. By maintaining a steady practice routine and focusing on continuous improvement, you'll develop advanced typing skills that benefit your work and daily activities.",
      wordCount: 365,
      characterCount: 1950,
    },
    {
      id: 7,
      difficulty: "easy",
      text: "Developing strong typing skills is an important part of modern communication. Begin by familiarizing yourself with the keyboard layout and practicing regularly. Use typing exercises to build muscle memory and improve your speed. Remember to keep your hands relaxed and maintain a comfortable typing posture. As you gain experience, you will notice improvements in both speed and accuracy, leading to more efficient work and less fatigue. Learning to type efficiently can make a significant difference in your productivity. Begin with exercises that help you build muscle memory and improve accuracy. Gradually increase the difficulty and length of the texts you practice with. Remember to maintain proper posture and hand positioning to avoid strain and enhance your typing speed over time. Typing quickly and accurately can make a big difference in your productivity. Start with basic typing exercises and gradually increase the complexity of the texts you use for practice. Pay close attention to your typing posture and finger placement to avoid strain and improve efficiency. Regular practice will help you build speed and accuracy, making your typing tasks smoother and more enjoyable.",
      wordCount: 350,
      characterCount: 1800,
    },
    {
      id: 8,
      difficulty: "medium",
      text: "Typing efficiently is a skill that can be developed through consistent practice and attention to technique. Start by focusing on accuracy before attempting to increase your typing speed. Use a variety of practice texts to challenge yourself and avoid getting stuck in a routine. Regularly assess your progress and set new goals to push your limits. With dedication and effort, you will achieve noticeable improvements in your typing skills. The process of learning to type efficiently involves understanding the mechanics of typing and developing good habits. Start by practicing with texts that challenge your skills and help you improve. Focus on maintaining proper technique, including hand positioning and posture. Track your progress with typing tests and set incremental goals to keep yourself motivated. With time and consistent effort, you'll achieve better typing speed and accuracy. Mastering typing requires more than just practice; it involves understanding how to position your hands correctly and developing a rhythm that allows for smooth and efficient typing. Utilize typing software or online tools to track your progress and identify areas for improvement. Set achievable goals for speed and accuracy, and make typing a regular part of your routine. Over time, these practices will help you become a more proficient typist.",
      wordCount: 375,
      characterCount: 1900,
    },
    {
      id: 9,
      difficulty: "hard",
      text: "Typing is an essential skill that can greatly impact your efficiency and productivity in various tasks. To enhance your typing abilities, engage in regular practice with diverse text samples. Concentrate on both speed and accuracy, and use typing software to monitor your progress. Incorporate ergonomic practices to prevent discomfort and strain. By maintaining a steady practice routine and focusing on continuous improvement, you'll develop advanced typing skills that benefit your work and daily activities. In today's digital world, typing quickly and accurately is a crucial skill for almost any profession. From writing emails and reports to coding and data entry, efficient typing can save you time and reduce the risk of errors. To improve your typing speed, practice with a variety of texts, including technical material, literature, and everyday content. Pay attention to your typing technique and work on building both speed and accuracy. The ability to type efficiently can greatly impact your productivity and effectiveness in various tasks. Whether you're drafting documents, entering data, or communicating with colleagues, fast and accurate typing skills are essential. Focus on maintaining proper posture and hand positioning to prevent strain and injury. Engage in regular practice sessions and challenge yourself with diverse texts to continually improve your typing speed and accuracy.",
      wordCount: 360,
      characterCount: 1850,
    },
    {
      id: 10,
      difficulty: "easy",
      text: "The ability to type quickly and accurately is a valuable asset in today’s fast-paced world. Begin by practicing with shorter texts and gradually move on to longer and more complex content. Aim to improve your speed and accuracy through consistent practice and by focusing on proper technique. Developing strong typing skills is an important part of modern communication. Begin by familiarizing yourself with the keyboard layout and practicing regularly. Use typing exercises to build muscle memory and improve your speed. Remember to keep your hands relaxed and maintain a comfortable typing posture. As you gain experience, you will notice improvements in both speed and accuracy, leading to more efficient work and less fatigue. Learning to type efficiently can make a significant difference in your productivity. Begin with exercises that help you build muscle memory and improve accuracy. Gradually increase the difficulty and length of the texts you practice with. Remember to maintain proper posture and hand positioning to avoid strain and enhance your typing speed over time. Typing quickly and accurately can make a big difference in your productivity. Start with basic typing exercises and gradually increase the complexity of the texts you use for practice. Pay close attention to your typing posture and finger placement to avoid strain and improve efficiency.",
      wordCount: 340,
      characterCount: 1750,
    }
  ];
  
  const [input, setInput] = useState("");
  const [randomN, setRandomN] = useState("");
  const [checkCountDown,setCheckCountDown] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [newTime,setNewTime] = useState(60);
  const [isTyping, setIsTyping] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const timerRef = useRef(null);
  const [totalChars, setTotalChars] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [correctChars, setCorrectChars] = useState(0);
  const [backG, setBackG] = useState(true);

// code to increase timer time
  useEffect(()=>{
    if(checkCountDown === 0){
      setTimeLeft(60);
      setNewTime(60)
    }else if(checkCountDown === 1){
      setTimeLeft(180);
      setNewTime(180);
    }else if(checkCountDown === 2){
      setTimeLeft(300);
      setNewTime(300);
    }
  },[checkCountDown])
 
  // Timer effect
  useEffect(() => {
    if (!isTyping || timeLeft <= 0) return;

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isTyping, timeLeft]);

  // Stop typing when timer reaches zero
  useEffect(() => {
    if (timeLeft <= 0) {
      setIsTyping(false);
      setShowPopup(true);
    }
  }, [timeLeft]);

  // Initialize the random text
  useEffect(() => {
    setRandomN(typData[Math.floor(Math.random() * typData.length)].text);
  }, []);

  // Auto-focus input field on mount
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);

    if (!isTyping) {
      setIsTyping(true);
    }

    // Calculate total words and characters
    setTotalWords(newInput.split(/\s+/).filter(Boolean).length);
    setTotalChars(newInput.length);

    // Update correct characters
    const currentText = randomN.slice(0, newInput.length);
    const correctCharsCount = [...currentText].filter(
      (char, index) => char === newInput[index]
    ).length;
    setCorrectChars(correctCharsCount);
  };

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2,"0")}`;
  };

  // Calculate WPM, CPM, and accuracy
  const calculateMetrics = () => {
    const elapsedTimeInMinutes = (newTime - timeLeft) / newTime;
    const wpm = (totalWords / elapsedTimeInMinutes).toFixed(2);
    const cpm = (totalChars / elapsedTimeInMinutes).toFixed(2);
    const accuracy =
      totalChars === 0 ? 0 : ((correctChars / totalChars) * 100).toFixed(2);

    return { wpm, cpm, accuracy };
  };

  const metrics = calculateMetrics();

  // Generate styled text
  const generateStyledText = () => {
    return randomN.split("").map((char, index) => {
      const userChar = input[index] || "";
      let color;

      if (index < input.length) {
        if (char === userChar) {
          color = "black";
        } else {
          color = "red";
        }
      } else {
        color = "grey";
      }

      return (
        <span key={index} style={{ color }}>
          {char}
        </span>
      );
    });
  };

  // Handle restart
  const handleRestart = () => {
    window.location.reload();
  };

  // Handle Enter key for restart
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && showPopup) {
        handleRestart();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [showPopup]);

  // Styles for the popup
  const popupStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    popup: {
      backgroundColor: "#03346E",
      padding: "2rem",
      borderRadius: "8px",
      boxShadow: "-4px 4px 18px #37b7c3",
      textAlign: "center",
      color: "white",
    },
    button: {
      marginTop: ".5rem",
      padding: "0.5rem 1rem",
      fontSize: "1rem",
      cursor: "pointer",
      backgroundColor: "#021526",
      color: "white",
      borderRadius: ".5rem",
    },
  };

  const handleToggle = () => {
    setBackG(!backG)
  }

  return (
    <body style={{backgroundImage:`url(${backG ? backImgDark : backImgWhi})`, height:'200vh'}}>
<div style={{paddingTop:'3rem'}}>
  <center>
  <div style={{paddingLeft:'50%'}}>

  <input type="checkbox" className="checkbox" id="checkbox" onChange={handleToggle}/>
  <label for="checkbox" className="checkbox-label">
    {/* <i className="fas fa-moon"></i>
    <i className="fas fa-sun"></i> */}

    <FontAwesomeIcon icon={faMoon} />
    <FontAwesomeIcon icon={faSun} />
    <span className="ball"></span>
  </label>
</div>
<h4 style={{padding: '5px 10px', backgroundColor:'#0e2a47', color:'white',width:'fit-content',borderRadius:'5px',boxShadow:'0px 0px 15px #37b7c3'}}>Select Timer Duration</h4>
<button style={{width:'10rem', height:'2rem', backgroundColor:'#071952', margin:'5px',borderRadius:'10px',color:'white',boxShadow:'0px 0px 15px #37b7c3'}} onClick={() => setCheckCountDown(0)}>1 Min</button>
<button style={{width:'10rem', height:'2rem', backgroundColor:'#071952', margin:'5px',borderRadius:'10px',color:'white',boxShadow:'0px 0px 15px #37b7c3'}} onClick={() => setCheckCountDown(1)}>3 Min</button>
<button style={{width:'10rem', height:'2rem', backgroundColor:'#071952', margin:'5px',borderRadius:'10px',color:'white',boxShadow:'0px 0px 15px #37b7c3'}} onClick={() => setCheckCountDown(2)}>5 Min</button>
</center>
</div>
      <div
        style={{
          display: "flex",
          marginLeft: "20%",
          marginTop: "2rem",
          alignItems: "center",
          width: "60%",
          height: "12rem",
          backgroundColor: "#071952",
          borderRadius: "1rem",
          border: "5px solid #37b7c3",
        }}>
        <div
          style={{
            width: "50%",
            height: "7rem",
            backgroundColor: "white",
            borderRadius: "1rem",
            marginLeft: "1rem",
            border: "2px solid #37B7C3"
          }}
        >
          <center>
            <div>
              <p  style={{ color: "#071952", fontWeight: "bold", fontSize: "3rem", marginBottom:'0', marginTop:'0' }}>{formatTime(timeLeft)}</p>
              <h6 style={{color:'#071952',fontSize:'1.5rem', marginTop:'0'}}>seconds</h6>

            </div>
          </center>
        </div>

        <div
          style={{
            width: "15%",
            marginLeft: "3%",
            color: "white",
            marginTop: "-20px",
          }}>
          <div style={{ height: "2rem" }}>
            <h1>
              {Number(metrics.wpm === "NaN" ? 0 : metrics.wpm)}{" "}
            </h1>
          </div>
          <div style={{ height: "2rem", marginTop: "10px" }}>
            <h1>
              {Number(metrics.cpm === "NaN" ? 0 : metrics.cpm)}{" "}
            </h1>
          </div>
          <div style={{ height: "2rem", marginTop: "10px" }}>
            <h1>
              {metrics.accuracy}{" "}
            </h1>
          </div>
        </div>
        <div
          style={{
            width: "15%",
            marginLeft: "3%",
            color: "white",
            marginTop: "-20px",
          }}>
          <div style={{ height: "2rem" }}>
            <h1>
              <span style={{ fontSize: "20px" }}>words/min</span>
            </h1>
          </div>
          <div style={{ height: "2rem", marginTop: "10px" }}>
            <h1>
              <span style={{ fontSize: "20px" }}>chars/min</span>
            </h1>
          </div>
          <div style={{ height: "2rem", marginTop: "10px" }}>
            <h1>
              <span style={{ fontSize: "20px" }}>% accuracy</span>
            </h1>
          </div>
        </div>
      </div>

      <div className="container"></div>

      <div className="container">
        <div style={{ display: "flex", paddingTop: "1rem" }}>
          <center>
            <div
              style={{
                width: "70%",
                backgroundColor: "white",
                padding: "1rem",
                borderRadius: "20px",
                boxShadow: "-4px 4px #37b7c3",
                  border:'2px solid #37b7c3'
              }}
            >
              <p
                style={{
                  color: "#0e2a47",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginTop:'0'
                }}
              >
                Start typing the below text:
              </p>
              <div style={{fontSize:'1.5rem'}}>{generateStyledText()}</div>
            </div>
          </center>
        </div>

        <center>
          <input
            ref={inputRef}
            style={{
              display: "flex",
              width: "2rem",
              height: "5rem",
              fontSize: "2rem",
              fontWeight: "bold",
              opacity: "0",
              color: "grey",
              placeHolder: 'Click Here to Start'
            }}
            type="text"
            disabled={timeLeft <= 0}
            onChange={handleInputChange}
          />
        </center>
      </div>

      {/* Popup Component */}
      {showPopup && (
        <div style={popupStyles.overlay}>
          <div style={popupStyles.popup}>
            <h2>Time's Up!</h2>
            <p>
              <strong>Words per Minute (WPM):</strong>{" "}
              {metrics.wpm === "NaN" ? 0 : metrics.wpm}
            </p>
            <p>
              <strong>Characters per Minute (CPM):</strong>{" "}
              {metrics.cpm === "NaN" ? 0 : metrics.cpm}
            </p>
            <p>
              <strong>Accuracy:</strong> {metrics.accuracy}%
            </p>
            <button onClick={handleRestart} style={popupStyles.button}>
              Restart
            </button>
            <p>
              <br />
              Click Enter To Restart
            </p>
          </div>
        </div>
      )}
    </body>
  );
};

export default SpeedTyping;